<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import DatePicker from '../DatePicker/DatePicker.vue'

const headers = ['SELECT', 'ANO', 'ESTADO', 'DATA PAGAMENTO']
let associateservice

const status = {
  true: 'Pago',
  false: 'Por Pagar',
}
const statusColor = {
  true: 'success',
  false: 'error',
}

const data = ref([])

function dateChanged(date) {
  this.profile.birthDate = date
}

function signDateChanged(date) {
  this.profile.signDate = date
}

export default defineComponent({
  components: {
    DatePicker,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    payments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    genderRule() {
      return value => value === 'm' || value === 'f' || 'Gender must be either "m" or "f"'
    },
  },
  data() {
    return {
      originalProfile: JSON.parse(JSON.stringify(this.profile)),
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.profile = this.originalProfile
    },
    save(profile) {
      associateservice.updateAssociate(profile.id, profile)
        .then(() => {
          this.$emit('refresh')
          alert('User updated with sucess') // eslint-disable-line no-alert
          this.dialog = false
        })
        .catch(() => alert('something went wrong')) // eslint-disable-line no-alert
    },
    pay(profileId, payments) {
      const filteredPayments = payments
        .filter(s => s.isSelected)
        .map(s => ({
          associatePaymentYearId: s.associatePaymentYearId,
          associatePaymentYear: s.associatePaymentYear,
        }))

      associateservice.pay(profileId, filteredPayments)
        .then(() => {
          this.$emit('refresh')
          alert('Payments were updated') // eslint-disable-line no-alert
          this.dialog = false
        })
        .catch(() => alert('something went wrong')) // eslint-disable-line no-alert
    },
  },
  setup() {
    const dialog = inject('dialog-modal2')
    associateservice = inject('associateservice')

    const tabs = ref([
      { id: 'Information', label: 'Informacao' },
      { id: 'Payments', label: 'Pagamentos' },
    ])
    const selectedTab = ref('Informacao')

    return {
      dialog,
      dateChanged,
      tabs,
      selectedTab,
      headers,
      data,
      statusColor,
      status,
      signDateChanged,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-tabs v-model="selectedTab">
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
      >
        {{ tab.label }}
      </v-tab>
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.id"
      >
        <v-card
          v-if="tab.id==='Information'"
        >
          <v-card-title>
            <span class="headline">Informação Jogador</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="profile.fullName"
              label="Nome Completo"
            ></v-text-field>
            <v-text-field
              v-model="profile.displayName"
              label="Nome"
            ></v-text-field>
            <v-text-field
              v-model="profile.age"
              label="Idade"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="profile.email"
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="profile.address"
              label="Morada"
            ></v-text-field>
            <v-text-field
              v-model="profile.gender"
              :rules="[genderRule]"
              label="Gender (m or f)"
            ></v-text-field>
            <date-picker
              :label="'Data Nascimento'"
              :date="profile.birthDate"
              @date-changed="dateChanged"
            ></date-picker>
            <date-picker
              :label="'Data Entrada'"
              :date="profile.signDate"
              @date-changed="signDateChanged"
            ></date-picker>
            <v-text-field
              v-model="profile.phone"
              label="Contacto"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="save(profile)"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              text
              @click="close"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="tab.id==='Payments'"
        >
          <v-card-title>
            <span class="headline">{{ tab.label }}</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table
              :headers="headers"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="header in headers"
                      :id="header"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="value in payments"
                    :key="value.associatePaymentYearId"
                  >
                    <td>
                      <v-checkbox
                        v-if="!value.isPaid"
                        v-model="value.isSelected"
                        hide-details
                        :readonly="value.isPaid"
                      >
                      </v-checkbox>
                    </td>
                    <td>
                      {{ value.associatePaymentYear }}
                    </td>
                    <td>
                      <VChip
                        small
                        :color="statusColor[value.isPaid]"
                        class="font-weight-medium"
                      >
                        {{ status[value.isPaid] }}
                      </VChip>
                    </td>
                    <td>
                      {{ value.paidDate }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="pay(profile.id, payments)"
            >
              Pagar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>
