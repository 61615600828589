<script>
import { mdiPencil } from '@mdi/js'
import {
  ref, inject, defineComponent, provide,
} from '@vue/composition-api'
import AssociateProfileModal from '@/layouts/components/Modals/AssociateProfileModal.vue'

let apiService
let apiAssociateService

const data = ref([])
const originalData = ref([])

const selectedItem = ref(null)
const items = ref([])

const headers = ['Ações', 'Nº Sócio', 'Nome', 'Pago', 'Contacto']

const profile = ref({})
const payments = ref([])

const modalOpen = ref(false)

const status = {
  true: 'Pago',
  false: 'Por Pagar',
}

const statusColor = {
  true: 'success',
  false: 'error',
}

function mapAssociatePayment(response) {
  let newPaidDate = response.paidDate

  if (newPaidDate != null) {
    newPaidDate = apiAssociateService.getParsedDate(response.paidDate)
  }

  return {
    associatePaymentYearId: response.associatePaymentYearId,
    associatePaymentYear: response.associatePaymentYear,
    isPaid: response.isPaid,
    paidDate: newPaidDate,
    isSelected: false,
  }
}

const sortPaymentYears = (a, b) => {
  if (a.associatePaymentYear > b.associatePaymentYear) {
    return 1
  }
  if (b.associatePaymentYear > a.associatePaymentYear) {
    return -1
  }

  return 0
}

const openModal = playerId => {
  apiService
    .get(`api/AssociateMember/${playerId}/payments`)
    .then(response => {
      const mappedResponse = []

      response.forEach(element => {
        mappedResponse.push(mapAssociatePayment(element))
      })

      payments.value = mappedResponse.sort((a, b) => sortPaymentYears(a, b))
    })
    .catch(error => {
      console.log(error)
      data.value = []
    })
  profile.value = JSON.parse(JSON.stringify(originalData.value.find(player => player.id === playerId)))
  modalOpen.value = true
}
const refreshAssociates = () => {
  apiAssociateService
    .getAsscoiates()
    .then(response => {
      data.value = response
      originalData.value = response
    })
    .catch(error => {
      console.log(error)
    })
}

const deleteAction = () => {
  data.value.forEach(element => {
    //
    if (element.isSelected) {
      // fetch ao serviço
      apiAssociateService
        .deletePlayer(element.id)
        .then(() => {
          apiAssociateService
            .getAsscoiates()
            .then(response => {
              data.value = response
              originalData.value = response
            })
            .catch(error => {
              console.log(error)
            })
          alert('UTILIZADOR APAGADO') // eslint-disable-line no-alert
        })
        .catch(error => {
          console.log(error)
        })
    }
  })
}

const closeModal = () => {
  modalOpen.value = false
}

function filter(value) {
  const values = originalData.value
  const input = value.toLocaleLowerCase()
  if (input === '' || input === undefined || input.length < 3) {
    data.value = values

    return
  }

  const terms = input.split(' ').filter(s => s.length >= 3)
  const joinedTerms = terms.join('|')
  const regexTerms = new RegExp(joinedTerms, 'g')

  data.value = values.filter(element => this.filtervalues(element, input, terms.length, regexTerms))
}

function compareIngoreCase(source, value) {
  return source.toLocaleLowerCase().indexOf(value) > -1
}

function compareFullName(source, value, inputTermsSize, regexTerms) {
  if (inputTermsSize === 1) {
    return compareIngoreCase(source, value)
  }
  const sourceLowerCase = source.toLocaleLowerCase()

  const matches = sourceLowerCase.match(regexTerms)

  if (matches == null) {
    return false
  }

  return matches.length === inputTermsSize
}

function filtervalues(element, input, inputTermsSize, regexTerms) {
  const lastIsPaid = element.lastIsPaid ? 'Pago' : 'Por Pagar'

  return (
    compareFullName(element.fullName, input, inputTermsSize, regexTerms)
    || compareIngoreCase(`${element.internalId}`, input)
    || compareIngoreCase(element.signDate, input)
    || compareIngoreCase(lastIsPaid, input)
  )
}

function logSelectedOption() {
  let uri = ''
  if (selectedItem.value.length === 0) {
    data.value = []
    originalData.value = []

    return
  }

  selectedItem.value.forEach(element => {
    uri += `clubLevels=${element}&`
  })

  apiService
    .get(`api/AssociateMember?${uri}`)
    .then(response => {
      const mappedResponse = response.map(element => apiAssociateService.mapAssociate(element))

      data.value = mappedResponse
      originalData.value = mappedResponse
    })
    .catch(error => {
      console.log(error)
      data.value = []
    })
}

export default defineComponent({
  components: {
    AssociateProfileModal,
  },
  setup() {
    apiService = inject('apiservice')
    apiAssociateService = inject('associateservice')
    provide('dialog-modal2', modalOpen)

    apiAssociateService
      .getAsscoiates()
      .then(response => {
        data.value = response
        originalData.value = response
      })
      .catch(error => {
        console.log(error)
      })

    return {
      icons: {
        mdiPencil,
      },
      data,
      originalData,
      headers,
      selectedItem,
      items,
      filter,
      filtervalues,
      logSelectedOption,
      apiService,
      apiAssociateService,
      profile,
      modalOpen,
      openModal,
      closeModal,
      deleteAction,
      payments,
      statusColor,
      status,
      refreshAssociates,
    }
  },
})
</script>

<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          placeholder="Pesquisar Sócios"
          outlined
          rounded
          @input="filter"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-btn
          @click="deleteAction"
        >
          Remover
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VCard>
          <v-simple-table
            :headers="headers"
            item-key="id"
            class="table-rounded"
            hide-default-footer
            disable-sort
            @input="filter"
          >
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :id="header"
                  :key="header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="row in data"
                :id="row.fullName"
                :key="row.id"
                :class="{ 'isForeverAssociate': row.isForeverAssociate }"
              >
                <td style="display: flex; align-items: center;">
                  <v-checkbox
                    v-model="row.isSelected"
                    hide-details
                  ></v-checkbox>

                  <v-btn
                    icon
                    @click="openModal(row.id)"
                  >
                    <v-icon>
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-btn>
                </td>
                <td v-text="row.internalId" />
                <td>
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ row.displayName }}</span>
                    <small>{{ row.post }}</small>
                  </div>
                </td>
                <td>
                  <!-- TODO: Fix size of button. It's applying the size of text. Should apply max size-->
                  <VChip
                    v-if="!row.isForeverAssociate"
                    small
                    :color="statusColor[row.lastIsPaid]"
                    class="font-weight-medium"
                  >
                    {{ status[row.lastIsPaid] }}
                  </VChip>
                </td>
                <td v-text="row.phone" />
              </tr>
            </tbody>
          </v-simple-table>
        </VCard>
      </v-col>
    </v-row>
    <div>
      <AssociateProfileModal
        :profile="profile"
        :payments="payments"
        :open="modalOpen"
        @close="closeModal"
        @refresh="refreshAssociates"
      ></AssociateProfileModal>
    </div>
  </div>
</template>
